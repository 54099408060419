<template>
  <div class="account-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item"
                             v-model="screen.searchKey"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="screen.searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
        <van-icon class="search-icon"
                  name="scan"
                  @click="scanIconBtn" />
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="vanPopupClick('assetCategory')">
          <span>{{ filter.assetCategory.label }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="filter-item"
             @click="vanPopupClick('status')">
          <span>{{ filter.status.label }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="filter-item"
             @click="vanPopupClick('usingStatus')">
          <span>{{ filter.usingStatus.label }}</span>
          <van-icon name="arrow-down" />
        </div>

        <div class="filter-item"
             @click="vanPopupClick('warehousing')">
          <span>{{ filter.warehousing.label }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>

      <van-popup v-model="showVanPopupType"
                 round
                 position="bottom">
        <custom-Popup :title="filter.title"
                      :columns="vanPopupTypeColumns"
                      value-key="name"
                      @cancel="vanPopupCancel"
                      @confirm="vanPopupConfirm" />
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemClick(item.id)">
            <item-card class="list-item"
                       :title="item.name"
                       :status="parseStatus(item.status)">
              <div class="content">
                <div class="content-line">
                  <span class="name">存放地点:</span>
                  <span class="value">{{ item.storagePlace }}</span>
                </div>
                <div class="content-line">
                  <span class="name">资产编码:</span>
                  <span class="value">{{ item.code }}</span>
                </div>
                <div class="content-line">
                  <span class="name">资产分类:</span>
                  <span class="value">{{ item.assetCategory }}</span>
                </div>
                <div class="content-line">
                  <span class="name">品牌名称:</span>
                  <span class="value">{{ item.brandName }}</span>
                </div>
                <div class="content-line">
                  <span class="name">规格型号:</span>
                  <span class="value">{{ item.specifications }}</span>
                </div>
                <div class="content-line">
                  <span class="name">所属单位:</span>
                  <span class="value">{{ item.organizationName }}</span>
                </div>
                <div class="content-line">
                  <span class="name">资产分类:</span>
                  <span class="value">{{ item.type=='OTHER'?"其它资产":"固定资产"}}</span>
                </div>
                <div class="content-line">
                  <span class="name">使用状态:</span>
                  <span class="value">{{ item.usingStatus | getUsingStatus }}</span>
                </div>
                <div class="content-line">
                  <span class="name">借用状态:</span>
                  <span class="value">{{ item.mayBorrow?'可借':'不可借'}}</span>
                </div>
                <!-- <div class="content-line">
                  <span class="name">二维码:</span>
                  <span class="text-button"
                        @click.stop="getCodeBtn(item.code, item.name)">查看</span>
                </div> -->
              </div>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <div class="footer">
      <!-- <van-uploader accept=".xlsx,.xls"
                    :after-read='addCommodity'>
        <van-button class="add-btn"
                    type="info"
                    round>新增</van-button>
      </van-uploader> -->
      <van-button class="add-btn"
                  type="info"
                  @click="addCommodity"
                  round>新增</van-button>
    </div>
    <!-- 二维码 -->
    <van-dialog v-model="showDialog"
                title="二维码">
      <div style="text-align:center">
        <VueQr :margin="8"
               :size="280"
               :whiteMargin="true"
               :logoMargin="3"
               :logoCornerRadius="20"
               :text="codeInfo.codeText"> </VueQr>
        <div>
          <div>编码:{{ codeInfo.codeText }}</div>
          <div>名称:{{ codeInfo.codeName }}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import "@/assets/less/views/assets/account.less";
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import VueQr from "vue-qr";
import wxapi from "@/lib/wechat";

import { flatMap, find } from "lodash-es";
export default {
  name: "CommodityList",
  data() {
    return {
      searchTypeOptions: [
        { text: "资产编码", value: "code" },
        { text: "资产名称", value: "name" },
        { text: "品牌名称", value: "brandName" }
      ],
      filter: {
        assetCategory: {
          id: "",
          label: "资产分类"
        },
        status: {
          id: "",
          label: "资产状态"
        },
        usingStatus: {
          id: "",
          label: "使用状态"
        },
        warehousing: {
          id: "",
          label: "所属仓库"
        },
        title: "资产分类"
      },
      listStatus: {
        loading: true,
        finished: false
      },
      list: [],
      showVanPopupType: false,
      vanPopupTypeColumns: [],
      pager: {
        pageNumber: 1,
        pageSize: 5
      },
      screen: {
        assetCategory: "",
        searchContent: "",
        searchKey: "code",
        usingStatus: "",
        status: ""
      },
      codeInfo: {
        codeText: null,
        codeName: null
      },
      showDialog: false
    };
  },
  methods: {
    itemClick(id) {
      this.$router.push({
        name: "accountEdit",
        query: {
          id
        }
      });
    },
    addCommodity(e) {
      // const inputFile = e.file;
      // console.log(1234, inputFile);
      // let formData = new FormData();
      // formData.append("file", inputFile);
      // this.postAssetsDetail({ params: formData });
      this.$router.push({
        name: "accountEdit",
        query: { type: "OTHER" }
      });
    },
    // =======================================
    init() {
      this.pager = {
        pageNumber: 1,
        pageSize: 5
      };
      this.getDictsList();
      this.getAssets();
    },
    vanPopupClick(type) {
      if (type == "assetCategory") {
        this.filter.title = "资产分类";
        this.vanPopupTypeColumns = [
          { id: "", name: "所有类型", key: "assetCategory" },
          ...this.assetsList.map(val => {
            return { id: val.value, name: val.value, key: "assetCategory" };
          })
        ];
      }
      if (type == "status") {
        this.filter.title = "资产状态";
        this.vanPopupTypeColumns = [
          { id: "", name: "全部", key: "status" },
          { id: "ENABLE", name: "正常", key: "status" },
          { id: "REPAIR", name: "报修", key: "status" },
          { id: "DAMAGED", name: "报损", key: "status" },
          { id: "DISCARDED", name: "报废", key: "status" }
        ];
      }
      if (type == "usingStatus") {
        this.filter.title = "使用状态";
        this.vanPopupTypeColumns = [
          { id: "", name: "全部", key: "usingStatus" },
          { id: "AVAILABLE", name: "可用", key: "usingStatus" },
          { id: "SECULAR_RECIPIENT", name: "长期领用", key: "usingStatus" },
          { id: "TEMPORARY_LENDING", name: "临时借出", key: "usingStatus" },
          { id: "SECULAR_RECIPIENT_UNDER_REVIEW", name: "长期领用审核中", key: "usingStatus" },
          { id: "TEMPORARY_LENDING_UNDER_REVIEW", name: "临时借出审核中", key: "usingStatus" }
        ];
      }
      if (type == "warehousing") {
        this.filter.title = "所属仓库";
        this.vanPopupTypeColumns = [
          { id: "", name: "全部", key: "warehousing" },
          ...this.warehouseOptions.map(val => {
            return { id: val.value, name: val.value, key: "warehousing" };
          })
        ];
      }
      this.showVanPopupType = true;
    },
    vanPopupCancel() {
      this.showVanPopupType = false;
    },
    vanPopupConfirm(val) {
      this.showVanPopupType = false;
      this.screen[val.key] = val.id;
      this.filter[val.key].label = val.name;
      this.filter[val.key].id = val.id;
      this.getInitialData();
    },
    getInitialData(query) {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.pager.pageNumber = 1;
      this.getAssets(false, query);
    },
    parseStatus(item) {
      if (item == "ENABLE") return "正常";
      if (item == "REPAIR") return "报修";
      if (item == "DAMAGED") return "报损";
      if (item == "DISCARDED") return "报废";
    },
    getCodeBtn(code, codeName) {
      this.codeInfo = {
        codeText: code,
        codeName
      };
      this.showDialog = true;
    },
    // 获取资产分类
    async getDictsList() {
      let ret = await this.$api.getDictsList({
        params: {
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.assetsList = ret.data["资产分类"][0].dictOptions;
      this.warehouseOptions = ret.data["所属仓库"][0].dictOptions;
    },
    // 获取资产列表
    async getAssets(sign = false, query) {
      let params = { pageNumber: this.pager.pageNumber - 1, pageSize: this.pager.pageSize };
      params.query = this.getQuery();
      if (query) params.query = query;
      let ret = await this.$api.getAssetsByQuery({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      // this.list = ret.data;
      this.pager.totalRecords = ret.totalRecords;
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    //  导入数据
    async postAssetsDetail(params) {
      let ret = await this.$api.postAssetsDetail(params);
      if (ret.code != SUCCESS_CODE) return this.$utils.warning("导入失败");
      this.$router.push({
        name: "accountEdit",
        params: {
          info: ret.data
        }
      });
      // this.$commonBus.$emit("open-in-tab", { name: "accountEdit", args: { info: ret.data } });
    },
    scanIconBtn() {
      let query;
      wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode", "barCode"],
        success: res => {
          query = "(barcode=*+" + res.resultStr + "+*)";
          this.getInitialData(query);
        }
      });
      // query = "(barcode=*123*)";
      // this.getInitialData(query);
    },
    onSearch() {
      this.getInitialData();
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery() {
      let filters = [];
      Object.keys(this.screen).forEach(key => {
        let filter = "equalTo";
        let value = this.screen[key];
        if (!this.screen[key] || key == "searchKey") return false;
        if (key == "searchContent") {
          key = this.screen.searchKey;
          value = this.screen.searchContent;
          filter = "contains";
        }
        let query = Filter.attribute(key)[filter](value);
        filters.push(query);
      });
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    onLoad() {
      this.getAssets(true);
    }
  },
  created() {
    this.init();
  },
  mounted() {
    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
  },
  components: { VueQr },
  filters: {
    getUsingStatus: item => {
      if (item == "AVAILABLE") return "可用";
      if (item == "SECULAR_RECIPIENT") return "长期领用";
      if (item == "TEMPORARY_LENDING") return "临时借出";
      if (item == "SECULAR_RECIPIENT_UNDER_REVIEW") return "长期领用审核中";
      if (item == "TEMPORARY_LENDING_UNDER_REVIEW") return "临时借出审核中";
    }
  }
};
</script>

<style></style>
